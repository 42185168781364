import { ReactNode } from "react";

import { PageWithAuth, RoleEnum } from "@mcp/interfaces";
import type { NextPage } from "next";
import dynamic from "next/dynamic";

const AllCasesPage = dynamic(() => import("@/containers/AllCases"), {
  ssr: false,
});

const Home: PageWithAuth<NextPage> = () => <AllCasesPage />;

Home.auth = {
  role: RoleEnum.admin,
};
export default Home;
